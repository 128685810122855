const state = {
  pages: {
    impressum: {
      title: "Impressum",
      text: `<h2>Dieses Angebot wird bereitgestellt von:</h2> <p><strong>dpa-infocom GmbH</strong><br>Mittelweg 38, 20148 Hamburg<br><strong>Telefon:</strong> +49 (0) 40
      4113-31000<br><strong>E-Mail:</strong> service (at) dpa-info.com<br><strong>Registereintragung:</strong>
      Amtsgericht Hamburg<br><strong>Handelsregister:</strong> Hamburg HRB
      76575<br><strong>Umsatzsteueridentifikationsnummer:</strong>
      DE813017939<br><strong>Geschäftsführer:</strong>Peter Kropsch • Jirka Albig</p> <h2>Inhaltlich verantwortlich:</h2> <p><strong>Teresa Dapp</strong><br>Redaktionsleiter Digital<br>dpa-infocom GmbH<br>Mittelweg 38, 20148
      Hamburg<br><strong>Telefon:</strong> +49 (0) 40 4113-31000<br><strong>E-Mail:</strong> service (at)
      dpa-info.com</p> <p>© dpa-infocom GmbH. Alle Inhalte dieser Website sind urheberrechtlich geschützt. Jegliche Nutzung von
      Inhalten, Texten, Grafiken und Bildern dieser Website ist ohne ausdrückliche schriftliche Zustimmung der
      dpa-infocom unzulässig. Dies gilt insbesondere für die Verbreitung, Vervielfältigung und öffentliche
      Wiedergabe sowie Speicherung, Bearbeitung oder Veränderung. Alle Rechte bleiben vorbehalten.</p> <p>Hinweis: Alle auf der Website gemachten Angaben werden mit größter Sorgfalt recherchiert und aktualisiert.
      Dennoch übernimmt die dpa-infocom GmbH keine Haftung für die Richtigkeit. Eine Haftung für Schäden,
      einschließlich entgangenen Gewinns, welche in Verbindung mit den auf dieser Site gegebenen Inhalten
      eintreten sollten, ist ausgeschlossen.</p>`
    },
    "slack-community": {
      title: "Slack-Community",
      text: `<h2>Was machen wir im Faktencheck-Club auf Slack?</h2>
      <ul>
        <li>Wir bieten der Faktencheck-Community eine Plattform, auf der sie sich medienübergreifend über ihre Arbeit austauschen kann.</li>
        <li>Wir empfehlen hilfreiche Tools für die digitale Recherche und bieten auch einige Übungsaufgaben an.</li>
        <li>Wir stehen mit Rat und Tat zur Seite, wenn es Fragen zu Recherchetechniken oder verwandten Themen gibt.</li>
      </ul>
      <h2>Wie funktioniert der Austausch auf Slack?</h2>
      <ul>
        <li>Slack ist ein Kommunikationstool, mit dem man chatten und konferieren, aber auch Bilder und Videos senden kann.</li>
        <li>Wir nutzen Slack, um über Faktenchecks und Techniken digitaler Recherche zu reden.</li>
        <li>Wir vom Faktencheck-Team der dpa sind die Hosts des Faktencheck-Clubs auf Slack. Er ist jedoch keine offizielle Ausspielplattform für Inhalte der dpa.</li>
        <li>Alle Teilnehmerinnen und Teilnehmer unserer Basisschulung sind herzlich eingeladen, sich dort einzubringen. Auch interessierte Kolleginnen und Kollegen sind willkommen.</li>
      </ul>
      <h2>Wie kann ich mitmachen?</h2>
      <ul>
        <li>Schreib uns gerne von Deiner Dienstadresse aus eine Mail an projektfaktencheck21@dpa.com. Wir nehmen dann Kontakt zu Dir auf.</li>
      </ul>
      <div class="text-center mt-8">
        <a href="mailto:projektfaktencheck21@dpa.com?subject=Einladung zur Faktencheck-Community auf Slack&body=Liebes%20Faktencheck-Team%20der%20dpa%2C%0A%0A%0Aich%20arbeite%20als%20%5B%E2%80%A6%5D%20f%C3%BCr%20%5B%E2%80%A6%5D%0A%0Aund%20bin%20an%20Eurer%20Faktencheck-Community%20auf%20Slack%20interessiert.%20K%C3%B6nntet%20Ihr%20mir%20eine%20Einladung%20schicken%3F%0A%0AVielen%20Dank.%0A%0A%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen%2C%0A%0A%5B%E2%80%A6%5D" class="inline-block px-4 py-1 bg-blue hover:bg-blue-dark cta">Anfrage stellen</a>
      </div>`
    }
  }
};

export default {
  state,
  namespaced: true
};
